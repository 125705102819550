import React, {useState, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import AuthService from "../services/auth.service";
import ProjectService from "../services/project.service";
import ProjectComponent from "../components/project.component";
import FilterCategoryComponent from "../components/filterCategory.component";
import "./styles/projects.scss";
import CategoryService from "../services/category.service";

const ProjectsPage = ({notifications}) => {
    const [redirect, setRedirect] = useState(null);
    const [projectsReady, setProjectsReady] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [filter, setFilter] = useState({search: "", category: "",});
    const [projects, setProjects] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const history = useHistory();
    const query = new URLSearchParams(window.location.search);
    const search = query.get('search') ?? '';
    const selectCategory = query.get('category') ?? '';

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (!user) setRedirect("/");

        filter.search = search;
        filter.category = selectCategory;
        setFilter(filter);

        fetchProjects();
        fetchCategories();
    }, [activePage]);

    const changeHistory = () => {
        let string = '';
        Object.keys(filter).map((el) => {
            string += `&${el}=${filter[el]}`
        });

        history.push({
            search: string,
        })
    }

    const fetchProjects = () => {
        setProjectsReady(false);

        let query = new URLSearchParams(window.location.search);
        let search = query.get('search') ?? '';
        let selectCategory = query.get('category') ?? '';

        ProjectService.projectsList(activePage, search, selectCategory).then((res) => {
            setProjects(res.data.items);
            setProjectsReady(true);
            setPagesCount(res.data.lastPage);
        });
    }

    const fetchCategories = () => {
        CategoryService.categoriesList().then((res) => {
            setCategoriesList(res.data);
        });
    }

    const handlePageChange = (event, page) => {
        setActivePage(page);
    }

    const handleSearchChange = (event) => {
        const query = event.currentTarget.value;

        filter.search = query;
        setFilter(filter);

        changeHistory();
    };

    const onChangeHandler = (e) => {
        const {value} = e.target;

        filter.category = value;
        setFilter(filter);

        changeHistory();
        fetchProjects();
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }


    return (
        <div className="page-projects">
            {(projectsReady) ?
                <div className="row">
                    <div className="col-12 col-md-3 mb-4">
                        <div className="title">
                            Усі завдання
                        </div>

                        <div className="search">
                            <input type="text" placeholder="Пошук..."
                                   onChange={handleSearchChange}
                                   onBlur={fetchProjects}
                                   value={search}/>
                        </div>

                        <form>
                            <div className="cat-title">
                                Выберите категорию
                            </div>

                            <div className="cat-list pc scrollbar">
                                <ul>
                                    {categoriesList && categoriesList.map((category, index) =>
                                        <FilterCategoryComponent
                                            key={index} category={category} onChangeHandler={onChangeHandler}/>)}
                                </ul>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-md-9 mb-4">
                        <div className="card-list">
                            {projects && projects.length === 0 &&
                                    <div className="project">
                                        <div className="card block mt-5">
                                            Завдань поки немає... Спробуйте повернутись сюди трохи пізніше
                                        </div>
                                </div>
                            }
                            {projects && projects.map((project, index) =>
                                <ProjectComponent key={index} project={project} notifications={notifications}/>)}
                            {pagesCount > 1 && <Pagination
                                page={activePage}
                                count={pagesCount}
                                onChange={handlePageChange}
                                color="secondary"
                                className="mt-3"
                            />}
                        </div>

                    </div>
                </div> : null}
        </div>
    );
}

export default ProjectsPage;
