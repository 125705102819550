import React, {useState} from 'react';
import * as Yup from "yup";
import {FaUpload} from 'react-icons/fa';
import {Formik, Form, ErrorMessage} from 'formik';
import FormData from 'form-data'
import {MdAccountBox} from "react-icons/all";
import UserService from "../../services/user.service";
import PropTypes from "prop-types";

const UpdatePhoto = ({currentUser, setCurrentUser}) => {
    const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);

    const handleUpload = (e) => {
        let data = new FormData();

        data.append('photo', e.photo);

        UserService.updatePhoto(data)
            .then((resp) => {
                //UserService.refreshProfileData();

                setShowSuccessfulMessage(true);
            })
            .catch(() => {
                setShowSuccessfulMessage(false);
            });
    }

    const validationSchema = Yup.object().shape({
        photo: Yup.string()
            .required('Будь ласка виберіть фото!'),
    });

    return (
        <div>
            <div className='card-form'>
                {!currentUser || (currentUser && (!currentUser.profile || !currentUser.profile.photo)) && <div className="profile-image-none center">
                    <MdAccountBox/>
                </div>}

                {currentUser && currentUser.profile && <div className="profile-image center">
                    <img src={currentUser.profile.photo} alt=""/>
                </div>}

                <Formik
                    initialValues={{
                        photo: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(e, {resetForm}) => {
                        handleUpload(e);
                    }}
                >
                    {({values, setFieldValue}) => (
                        <Form>
                            <input name="photo"
                                   type="file"
                                   accept="image/*"
                                   onChange={(event) => {
                                       setFieldValue("photo", event.currentTarget.files[0]);
                                   }}
                                   className="form-control upload-file-hidden"
                            />
                            <label className="upload-file-label">
                                <FaUpload/>
                                <span>Виберіть фото</span>
                            </label>
                            <ErrorMessage name='photo'>
                                {
                                    msg => <div className='error'>{msg}</div>
                                }
                            </ErrorMessage>

                            <div className="form-group mt-3 mb-0">
                                <button className="btn download w-100">
                                    Завантажити
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {
                showSuccessfulMessage ? <div className='error successfulMessage mt-3'>Фото було обновлене!</div> : null
            }
        </div>
    );
}

UpdatePhoto.propTypes = {
    currentUser: PropTypes.object,
    setCurrentUser: PropTypes.func,
};

export default UpdatePhoto;
