import React, {useState, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import * as Yup from 'yup';
import AuthService from "../services/auth.service";
import CategoryService from "../services/category.service";
import ProjectService from "../services/project.service";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import LeftBar from "../components/leftBar.component";

const CreateProjectPage = () => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [redirect, setRedirect] = useState(null);
    const [categories, setCategories] = useState([]);
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Це поле не може бути порожнім'),
        description: Yup.string()
            .required('Це поле не може бути порожнім'),
        duration: Yup.string()
            .required('Це поле не може бути порожнім')
            .test("min", "Тривалість має бути від 1 год.", (number) => number >= 1)
            .test("max", "Тривалість має бути менше 10 000 год.", (number) => number < 10000)
        ,
        price: Yup.string()
            .required('Це поле не може бути порожнім')
            .test("min", "Ціна повинна бути 40 грн або більше.", (number) => number >= 40)
            .test("max", "Ціна повинна бути менше 20000 грн.", (number) => number < 20000),
        category_id: Yup.string()
            .required('Це поле не може бути порожнім')
    });

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/");

        fetchCategories();
    }, []);

    const fetchCategories = () => {
        CategoryService.categoriesList().then((res) => {
            setCategories(res.data);
        });
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    const handleCreateProject = (data) => {
        setShowErrorMessage(false);

        ProjectService.create(data.title, data.description, data.duration, data.price, data.category_id)
            .then(() => {
                history.push("/tasks")
            })
            .catch(error => {
                setShowErrorMessage(true);
                setErrorMessage(error.response.data.message);
            })
    }

    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
                duration: '',
                price: '',
                category_id: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleCreateProject}
        >
            {({handleSubmit}) => (
                <div>
                    <div className='row'>
                        <div className="col-12 col-lg-9 m-auto mb-5">
                            <h1 className="title">
                                Нове завдання
                            </h1>

                            <div className="card-form">
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="title">Назва</label>
                                        <Field type='text' name='title' className="form-control"/>
                                        <ErrorMessage name='title'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Опис</label>
                                        <Field as="textarea" name='description' className="form-control"/>
                                        <ErrorMessage name='description'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Тривалість виконання (год.)</label>
                                        <Field type='number' name='duration' className="form-control"/>
                                        <ErrorMessage name='duration'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Ціна</label>
                                        <Field type='number' name='price' className="form-control"/>
                                        <ErrorMessage name='price'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Категорія</label>
                                        <Field as="select" name="category_id" className="form-control" style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}>
                                            <option disabled value="" selected>Select category</option>
                                            {categories && categories.map(category => (
                                                <option value={category.id}>{category.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name='category_id'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Місто</label>
                                        <Field as="select" name="city_id" className="form-control" style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}>
                                            <option value="1" selected>Львів</option>
                                        </Field>
                                        <ErrorMessage name='city_id'>
                                            {
                                                msg => <div className='error'>{msg}</div>
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-block">
                                            <span>Створити</span>
                                        </button>
                                    </div>

                                    {
                                        showErrorMessage ?
                                            <div className='error errorMessage'>{errorMessage}</div> : null
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default CreateProjectPage;
