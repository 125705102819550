import React, {useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import AuthService from "../services/auth.service";
import ProjectService from "../services/project.service";
import TaskComponent from "../components/task.component";
import "./styles/tasks.scss";

const TasksPage = ({notifications, currentUser, setCurrentUser}) => {
    const [redirect, setRedirect] = useState(null);
    const [projectsReady, setProjectsReady] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [tasks, setTasks] = useState([]);
    const [pagesCount, setPagesCount] = useState(0);

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/");

        fetchTasks();
    }, [activePage]);

    const fetchTasks = () => {
        setProjectsReady(false);

        ProjectService.tasksList(activePage).then((res) => {
            setTasks(res.data.items);
            setProjectsReady(true);
            setPagesCount(res.data.lastPage);
        });
    }

    const handlePageChange = (event, page) => {
        setActivePage(page);
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div className="page-tasks">

            {(projectsReady) ?
                <div>
                    <div className='row'>
                        <div className="col-12 col-lg-9 m-auto mb-5">
                            <h1 className="title">
                                Ваші задання
                            </h1>
                            {tasks && tasks.length === 0 && <p>У вас немає завдань</p>}
                            {tasks && tasks.map((task, index) => <TaskComponent
                                key={index}
                                task={task}
                                fetchTasks={fetchTasks}
                                setCurrentUser={setCurrentUser}
                                currentUser={currentUser}
                                notifications={notifications}
                            />)}
                            {pagesCount > 1 && <Pagination
                                page={activePage}
                                count={pagesCount}
                                onChange={handlePageChange}
                                color="secondary"
                                className="mt-3"
                            />}
                        </div>
                    </div>
                </div> : null}
        </div>
    );
}

export default TasksPage;
