import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const FilterCategoryComponent = ({category, onChangeHandler}) => {
    const query = new URLSearchParams(window.location.search);
    const categoryId = query.get('category') ? query.get('category') : false;
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (category.id === Number(categoryId)) {
            setIsChecked(true);
        }
    }, []);

    return (
        <div>
            <li key={category.id}>
                <div className="radio mb-3">
                    <input type="radio"
                           id={'cat_' + category.id}
                           value={category.id}
                           name="category"
                           onChange={onChangeHandler}
                           checked={isChecked}
                    />
                    <label className="radio-label" htmlFor={'cat_' + category.id}>{category.name}</label>
                </div>
            </li>
        </div>
    );
}

FilterCategoryComponent.propTypes = {
    category: PropTypes.object,
    onChangeHandler: PropTypes.func,
};

export default FilterCategoryComponent;