import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  heartbeat() {
    return axios.get(API_URL + 'heartbeat', { headers: authHeader() });
  }

  me() {
    return axios.get(API_URL + 'me', { headers: authHeader() });
  }

  update(data) {
    return axios.post(API_URL + "me", data, { headers: authHeader() });
  }

  updatePhoto(data) {
    return axios.post(API_URL + "me/photo", data, { headers: authHeader() });
  }

  refreshProfileData(currentUser, setCurrentUser) {
    this.me().then((resp) => {
      const userInfo = {
        ...JSON.parse(localStorage.getItem("user")),
        ...resp.data
      };

      localStorage.setItem("user", JSON.stringify(userInfo));

      setCurrentUser(userInfo);
    });
  }

  getUserComments(id) {
    return axios.get(API_URL + `user/${id}/comments`, { headers: authHeader() });
  }
}

export default new UserService();
