import React, {useState, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import UpdatePhoto from "../components/profile/updatePhoto.component";

const ProfilePage = ({currentUser, setCurrentUser}) => {
    const [redirect, setRedirect] = useState(null);
    const [profile, setProfile] = useState({});
    const history = useHistory();
    const [gender, setGender] = useState(null);
    const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const phoneNumberMask = ["(", /[0-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    const options = [
        {value: "male", label: "Чоловік"},
        {value: "female", label: "Жінка"},
        {value: "i prefer not to say", label: "Я вважаю за краще не говорити"},
    ];

    const colourStyles = {
        control: styles => ({
            ...styles,
            border: 0,
            padding: 0,
            background: '#f2f5fc',
        }),
    };

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/login");

        fetchProfile();
    }, []);

    const fetchProfile = () => {
        UserService.me().then((res) => {
            setProfile(res.data);
            setGender(res.data?.profile?.gender);
        });
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    const logout = () => {
        AuthService.logout();
        setCurrentUser(undefined)
        history.push("/login");
    }

    const handleTypeSelect = e => {
        setGender(e.value);
    };

    const handleUpdateProfile = (e) => {
        let data = new FormData();

        data.append('name', e.name);
        data.append('email', e.email);
        data.append('new-password', e.password);
        data.append('profile[phone_number]', e.phone_number);
        data.append('profile[description]', e.description ?? '');
        data.append('profile[gender]', gender);

        UserService.update(data)
            .then(() => {
                setShowErrorMessage(false);
                setShowSuccessfulMessage(true);
            })
            .catch(error => {
                setShowSuccessfulMessage(false);
                setShowErrorMessage(true);
                setErrorMessage(error.response.data.message);
            });
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Це поле не може бути порожнім'),
        email: Yup.string()
            .email("Enter a valid email")
            .required('Це поле не може бути порожнім'),
        phone_number: Yup.string()
            .required("Це поле не може бути порожнім"),
        password: Yup.string()
            .min(7, "Your password must be longer than 6 characters.")
            .max(25),
    });

    return (
        <div>
            <h1 className="title">
                Мій профіль
            </h1>
            <div className='row'>
                <div className="col-12 col-lg-9 mb-4">
                    {
                        showSuccessfulMessage ?
                            <div className='error successfulMessage mb-4'>Ваші дані були успішно обновлені!</div> : null
                    }
                    {
                        showErrorMessage ? <div className='error errorMessage mb-4'>{errorMessage}</div> : null
                    }
                    <div className="card-form">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: profile?.name,
                                email: profile?.email,
                                password: '',
                                description: profile.profile?.description,
                                phone_number: profile?.profile?.phone_number,
                                gender: profile?.profile?.gender,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdateProfile}
                        >
                            {({handleChange, handleBlur, touched, errors}) => (
                                <Form>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field type='email' name='email' className="form-control"/>
                                                <ErrorMessage name='email'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="phone_number">Номер телефону</label>
                                                <Field
                                                    type='text'
                                                    name='phone_number'
                                                    className="form-control"
                                                    render={({field}) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="phone_number"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.phone_number && touched.phone_number
                                                                    ? "text-input error"
                                                                    : "text-input"
                                                            }
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage name='phone_number'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="password">Пароль</label>
                                                <Field type='password' name='password' className="form-control"
                                                       autoComplete="new-password"/>
                                                <ErrorMessage name='password'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Ім'я</label>
                                                <Field type='text' name='name' className="form-control"/>
                                                <ErrorMessage name='name'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="description">Про вас</label>
                                                <Field type='text' name='description' className="form-control"/>
                                                <ErrorMessage name='description'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="gender">Ваша стать</label>
                                                <Select
                                                    options={options}
                                                    onChange={handleTypeSelect}
                                                    value={options.filter(function (option) {
                                                        return option.value === gender;
                                                    })}
                                                    styles={colourStyles}
                                                    name="gender"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-end mt-4">
                                        <button className="btn me-3" onMouseDown={logout}>
                                            Вийти
                                        </button>
                                        <button className="btn">
                                            Зберегти
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="col-12 col-lg-3 mb-4">
                    <UpdatePhoto setCurrentUser={setCurrentUser} currentUser={currentUser} />
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
