import React, {useEffect, useState} from 'react';
import ProjectService from "../services/project.service";
import STATUSES from "../constants/projectStatuses";
import PropTypes from "prop-types";
import Chat from "./messages/chat.component";
import UserService from "../services/user.service";
import ModalConfirm from "./modals/modalConfirm.component";

const RoomComponent = ({room, fetchRooms, project, fetchTasks, index, currentUser, setCurrentUser}) => {
    const [messages, setMessages] = useState('');
    const [chatRef, setChatRef] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState('');

    const handleClickOpen = (type) => {
        setOpen(true);
        setModalType(type)
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    const scrollToBottom = () => {
        if (chatRef) chatRef.scrollTo(0, 1000000000);
    }

    const fetchMessages = () => {
        ProjectService.getRoomMessages(room.id).then((res) => {
            setMessages(res.data.messages);
        });
    }

    const applyExecutor = (executorId, projectId) => {
        ProjectService.applyExecutor(executorId, projectId)
            .then((res) => {
                fetchTasks();

                UserService.refreshProfileData(currentUser, setCurrentUser);
            })
    }

    const payAndClose = (projectId) => {
        ProjectService.payAndClose(projectId)
            .then((res) => {
                fetchTasks();

                UserService.refreshProfileData(currentUser, setCurrentUser);
            })
    }

    const dispute = (projectId) => {
        ProjectService.dispute(projectId)
            .then((res) => {
                fetchTasks();
            })
    }

    const returnInProgress = (projectId) => {
        ProjectService.returnInProgress(projectId)
            .then((res) => {
                console.log('returned in progress');
            });
    }

    return (<div key={index}>
        <Chat
            messages={messages}
            project={project}
            user={currentUser}
            room={room}
            fetchMessages={fetchMessages}
            setChatRef={setChatRef}
            scrollToBottom={scrollToBottom}
        />
        <div className="actions-area mt-3">
            {project.status === STATUSES.open &&
                <button className="btn" onClick={() => handleClickOpen('choice')}>Обрати виконавця</button>}

            {(project.status === STATUSES.progress || project.status === STATUSES.review) && <>
                <button className="btn me-3" onClick={() =>
                    handleClickOpen('dispute')
                }>Суперечка
                </button>
            </>}

            {(project.status === STATUSES.progress || project.status === STATUSES.review || project.status === STATUSES.dispute) && <>
                <button className="btn me-3" onClick={() => handleClickOpen('pay')}>Оплатити та закрити</button>
            </>}

            {project.status === STATUSES.review &&
                <button className="btn" onClick={() => handleClickOpen('return')}>Відновити</button>}
        </div>

        <ModalConfirm roomCreator={room.creator_id} roomProject={room.project_id} open={open} setOpen={setOpen} applyExecutor={applyExecutor}
                      dispute={dispute} payAndClose={payAndClose} returnInProgress={returnInProgress}
                      modalType={modalType}/>
    </div>);
}

RoomComponent.propTypes = {
    room: PropTypes.object,
    fetchRooms: PropTypes.func,
    project: PropTypes.object,
    fetchTasks: PropTypes.func,
    index: PropTypes.number,
};

export default RoomComponent;
