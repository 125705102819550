import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import UserService from "../../services/user.service";
import {VscAccount} from "react-icons/all";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";

const ModalComments = ({data, modalIsOpen, setIsOpen}) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (user) {
            fetchComments();
        }
    }, [data]);

    const handleClose = () => {
        setIsOpen(false);
    };

    const user = data?.creator;

    const fetchComments = () => {
        UserService.getUserComments(user.id).then((res) => {
            setComments(res.data.comments);
        });
    }

    return (
        <Dialog
            open={modalIsOpen}
            onClose={handleClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="user-comment-profile">
                        <div className="text-center p-3 mb-3 block mt-4 bg-blue-second">
                            <div className="mb-3">
                                {!user?.profile?.photo && <VscAccount className="image"/>}
                                {user?.profile?.photo && <img className="image" src={user?.profile?.photo} alt=""/>}
                            </div>
                            <p>
                                <p><b>Виконавець:</b> {user?.name}</p>
                            </p>
                            <p className='mb-0'>
                                <b>Рейтинг:</b> {data?.rating}%
                            </p>
                        </div>
                        <ul className="comments">
                            {comments && comments.map((comment, index) =>
                                <li key={index} className={`mb-3 ${comment?.rating ? 'bg-green-second' : 'bg-pink-second'}`}>
                                    <p className="mb-0">
                                        <strong>{comment?.rating ? 'Позитивний: ' : 'Негативний: '}</strong>
                                        {comment?.comment || 'Замовник не вказав коментар.'}
                                    </p>
                                </li>)}
                        </ul>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Закрити
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalComments.propTypes = {
    modalIsOpen: PropTypes.bool,
    reportProject: PropTypes.object,
    setIsOpen: PropTypes.func,
};

export default ModalComments;
