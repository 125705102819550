import React, {useState, useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import AuthService from "../services/auth.service";
import {LiqPayPay, LiqPaySubscribe} from "react-liqpay";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {TextField} from "@mui/material";

const BalancePage = () => {
    const [currentUser, setCurrentUser] = useState({username: ""});
    const [userReady, setUserReady] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [amount, setAmount] = useState(100);
    const API_URL = process.env.REACT_APP_API_URL;

    const payInfo = {
        amount: amount,
        currency: 'грн.',
        title: 'Оплатити'
    }

    const handleSliderChange = (event, newValue) => {
        setAmount(newValue);
    };

    const handleInputChange = (event) => {
        setAmount(event.target.value);
    };

    const ButtonComponent = () => (
        <button className='btn' disabled={amount < 1}>
            {`${payInfo.title} ${payInfo.amount} ${payInfo.currency}`}
        </button>
    )

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/login");

        setCurrentUser(currentUser);
        setUserReady(currentUser);
    }, []);

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <div className='row'>
                <div className="col-12 col-lg-9 m-auto mb-5">
                    <h1 className="title">
                        Баланс
                    </h1>

                    <div className="card-form">
                        {(userReady) ?
                            <div>
                                <div className="form-group">
                                    <strong>Ваш баланс: </strong>
                                    {currentUser.balance} грн.
                                </div>

                                <div className="row align-items-center mb-4">
                                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                        <Box>
                                            <Slider
                                                defaultValue={30}
                                                onChange={handleSliderChange}
                                                value={amount}
                                                valueLabelDisplay="auto"
                                                step={100}
                                                marks
                                                min={100}
                                                max={5000}
                                            />
                                        </Box>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <TextField
                                            id="standard-number"
                                            label="Сума"
                                            type="number"
                                            value={amount}
                                            onChange={(event) => handleInputChange(event)}
                                            inputProps={{
                                                step: 50,
                                                min: 100,
                                            }}
                                            className="w-100"
                                        />
                                    </div>
                                </div>

                                <LiqPayPay
                                    amount={amount}
                                    currency="UAH"
                                    publicKey={process.env.REACT_APP_LIQ_PAY_PUBLIC_KEY}
                                    privateKey={process.env.REACT_APP_LIQ_PAY_PRIVATE_KEY}
                                    description={'Payment for user: ' + currentUser.id}
                                    result_url="https://freelance.dev.oporasolutions.com/balance"
                                    product_description={currentUser.id}
                                    extra={[<ButtonComponent key="1"/>]}
                                />
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BalancePage;
