import React, {useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import AuthService from "../services/auth.service";
import DisputeService from "../services/dispute.service";
import DisputeComponent from "../components/dispute.component";
import "./styles/disputes.scss";
import LeftBar from "../components/leftBar.component";

const DisputesPage = () => {
    const [redirect, setRedirect] = useState(null);
    const [disputesReady, setDisputesReady] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [disputes, setDisputes] = useState([]);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (!user) setRedirect("/");

        fetchDisputes();
    }, [activePage]);

    const fetchDisputes = () => {
        setDisputesReady(false);

        DisputeService.disputesList(activePage).then((res) => {
            setDisputes(res.data.projects.data);
            setDisputesReady(true);
            setPagesCount(res.data.lastPage);
        });
    }

    const handlePageChange = (event, page) => {
        setActivePage(page);
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <div className='row'>
                <div className="col-12 col-lg-9  m-auto mb-b">
                    <h1 className="title">
                        Суперечки
                    </h1>
                    {(disputesReady) ?
                        <div className="row">
                            <div className="col b-content scrollbar-content">
                                <div className="card-list">
                                    {disputes && disputes.map((dispute, index) => <DisputeComponent key={index}
                                                                                                    dispute={dispute}/>)}
                                    {pagesCount > 1 && <Pagination
                                        page={activePage}
                                        count={pagesCount}
                                        onChange={handlePageChange}
                                        color="secondary"
                                    />}
                                </div>

                            </div>
                        </div> : 'Немає суперечок'}
                </div>
            </div>
        </div>
    );
}

export default DisputesPage;
