import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import ProjectService from "../../services/project.service";
import * as Yup from "yup";
import {FaUpload} from 'react-icons/fa';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import FormData from 'form-data'
import AdminMessage from "./adminMessage.component";
import Message from "./message.component";
import {FaTelegramPlane} from "react-icons/all";

const ChatDispute = ({messages, dispute, scrollToBottom, fetchMessages, setChatRef}) => {
    const handleMessage = (e, disputeId) => {
        let data = new FormData();
        let attachments = e.attachments;

        if (attachments && attachments.length > 0) {
            Object.keys(e.attachments).map((el) => {
                data.append('attachments[]', e.attachments[el])
            });
        }

        data.append('text', e.message);
        data.append('project_id', disputeId);

        ProjectService.messageAdmin(data).then(() => {
                fetchMessages();
            }, (error) => {
                console.log('err sent');
            }
        );

        scrollToBottom();
    }

    const validationSchema = Yup.object().shape({
        message: Yup.string()
            .required('Це поле не може бути порожнім'),
    });

    return (
        <div className="card-messenger mt-4">
            <div className="row gx-0">
                <div className="col-12 col-lg-8">
                    <div className="chat msg-lg-body messages scrollbar-messenger"
                         ref={ref => {
                             scrollToBottom();
                             setChatRef(ref);
                         }}>
                        {messages.length > 0 && <ul>
                            {messages.map((message, i) => <div className="message" key={i}>
                                {(() => {
                                    if (message.is_admin) {
                                        return <AdminMessage message={message}/>
                                    } else {
                                        return <Message message={message} username={message.user.name}/>
                                    }
                                })()}
                            </div>)}
                        </ul>}
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div className="msg-lg-info">
                        <ul className='mb-3'>
                            <li className="author"><b>Замовник:</b> {dispute.owner.name}</li>
                            <li className="map"><b>Адреса:</b> ул. Садовая 3</li>
                        </ul>

                        <div className="msg-lg-input">
                            {dispute.messages !== 1 &&
                                <Formik
                                    initialValues={{
                                        message: '',
                                        attachments: null,
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(e, {resetForm}) => {
                                        handleMessage(e, dispute.id);
                                        resetForm({values: ''});
                                    }}
                                >
                                    {({values, setFieldValue}) => (
                                        <Form>
                                            <Field type='text'
                                                   name='message'
                                                   className="message"
                                                   placeholder="Напишіть повідомлення"
                                            />
                                            <button
                                                className="go"
                                            >
                                                <FaTelegramPlane/>
                                            </button>
                                            <ErrorMessage name='message'>
                                                {
                                                    msg => <div className='error'>{msg}</div>
                                                }
                                            </ErrorMessage>

                                            <input name="attachments"
                                                   type="file"
                                                   onChange={(event) => {
                                                       setFieldValue("attachments", event.currentTarget.files);
                                                   }}
                                                   className="form-control upload-file-hidden"
                                                   multiple
                                                   id={'upload-file-' + dispute.id}
                                            />
                                            <label className="upload-file-label"
                                                   htmlFor={'upload-file-' + dispute.id}>
                                                <FaUpload/>
                                                <span>Завантажити файли</span>
                                            </label>
                                        </Form>
                                    )}
                                </Formik>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ChatDispute.propTypes = {
    dispute: PropTypes.object,
    fetchMessages: PropTypes.func,
    setChatRef: PropTypes.func,
    scrollToBottom: PropTypes.func,
};

export default ChatDispute;