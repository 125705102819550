import React from 'react';
import Moment from 'moment';
import 'moment/locale/uk';
import PropTypes from "prop-types";
import {AiOutlineFileImage, AiOutlineFilePdf, AiOutlineVideoCamera} from "react-icons/ai";
import {IoMdDoneAll, MdOutlineRemoveDone} from "react-icons/all";

const Message = ({message, username, index}) => {
    return (
        <li key={index}>
            <div className="mess from">
                <div className="text">
                    {username && <b>{message.user.name} : </b>}
                    {message.text}
                </div>
                <div className="files">
                    {message.attachments && message.attachments.map((attachment, index) =>
                        <a key={index} className='file' href={attachment.path} target='_blank'>
                            {attachment.type === 'image' && <AiOutlineFileImage/>}
                            {attachment.type === 'pdf' && <AiOutlineFilePdf/>}
                            {attachment.type === 'video' && <AiOutlineVideoCamera/>}
                            <span className="name">{attachment.name}</span>
                        </a>)}
                </div>
                <div className="info">
                    {Moment(message.created_at).locale('uk').format('d MMM H:m')}
                    <span className="status ms-1">
                          {message.is_read
                              ? <IoMdDoneAll/>
                              : <MdOutlineRemoveDone/>
                          }
                    </span>
                </div>
            </div>
        </li>
    );
}

Message.propTypes = {
    message: PropTypes.object,
    username: PropTypes.string,
    index: PropTypes.number,
};

export default Message;