import React, {useState, useEffect} from 'react';
import ProjectService from "../services/project.service";
import AuthService from "../services/auth.service";
import PropTypes from "prop-types";
import Chat from "./messages/chat.component";
import STATUSES from "../constants/projectStatuses";
import {Alert} from "@mui/material";
import ModalReport from "./modals/modalReport.component";
import {IoMdClose, TbBrandTelegram, AiOutlineFieldTime, AiOutlineDollarCircle} from "react-icons/all";

const ProjectComponent = ({project, notifications}) => {
    const [messages, setMessages] = useState([]);
    const [chatOpened, setChatOpened] = useState(false);
    const [reportProject, setRreportProject] = useState({});
    const [user, setUser] = useState({});
    const [chatRef, setChatRef] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [pointMessage, setPointMessage] = useState(false);

    useEffect(() => {
        if (notifications.open.rooms.length && project.rooms.length) {
            notifications.open.rooms.forEach(id => {
                project.rooms.forEach(item => {
                    if (item.id == id) {
                        setPointMessage(true);
                    }
                })
            });
        }

    }, [notifications]);

    useEffect(() => {
        setUser(AuthService.getCurrentUser());
    }, []);

    const scrollToBottom = () => {
        if (chatRef)
            chatRef.scrollTo(0, 1000000000);
    }

    const fetchMessages = () => {
        ProjectService.getProjectMessages(project.id).then((res) => {
            setMessages(res.data.messages);
        }).then(() => {
            scrollToBottom();
        });

        scrollToBottom();
    }

    const toReview = (projectId) => {
        ProjectService.toReview(projectId).then((res) => {
            console.log('to review');
            alert('to review');
        });
    }

    const dispute = (projectId) => {
        ProjectService.dispute(projectId).then((res) => {
            console.log('disputed');
            alert('disputed');
        });
    }

    return (
        <div>
            <div className="mb-4 project">
                <div className="card block">
                    <div className="row mb-3 pe-5 align-items-center">
                        <div className="col-12 col-lg-7 col-xl-8 mb-3 mb-lg-0">
                            <div className="title">
                                {project.name}
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-4">
                            <div className="row info">
                                <div className="col-6">
                                    <div className="item">
                                        <AiOutlineFieldTime/>{project.duration || '-'} год.
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item ml-2">
                                        <AiOutlineDollarCircle/> {project.price || '-'} грн.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pe-lg-5 text-justify">
                        {project.details.description}
                    </div>

                    <div className="card-footer-pc">
                        <ul>
                            <li>
                                <a className="button"
                                   onClick={(e) => {
                                       setIsOpen(true);
                                       setRreportProject(project);
                                   }}
                                >
                                    <IoMdClose/>
                                </a>
                            </li>
                            {messages.length > 0 &&
                                <li>
                                        <span className={`button chat open-chat ${pointMessage ? 'animation' : ''}`}
                                              onClick={() => {
                                                  setPointMessage(false);
                                                  setChatOpened(!chatOpened)
                                              }
                                        }>
                                            <TbBrandTelegram/>
                                        </span>
                                </li>
                            }
                            {messages.length === 0 &&
                                <li>
                                    <a className={`button check ${pointMessage ? 'animation' : ''}`}
                                       onClick={() => {
                                           if (!chatOpened) {
                                               fetchMessages();
                                           }

                                           setPointMessage(false);
                                           setChatOpened(!chatOpened);
                                       }}
                                    ><TbBrandTelegram/></a>
                                </li>
                            }
                        </ul>
                    </div>

                    {chatOpened && <>
                        <Chat
                            messages={messages}
                            project={project}
                            user={user}
                            fetchMessages={fetchMessages}
                            setChatRef={setChatRef}
                            scrollToBottom={scrollToBottom}
                            disabledSecondMessage={true}
                        />
                        <div className="actions-area">
                            {project.status !== STATUSES.closed && <>
                                {project.status === STATUSES.progress &&
                                    <button className="btn btn-primary btn-block me-3" onClick={() => {
                                        toReview(project.id);
                                    }}>To review</button>}

                                {(project.status === STATUSES.progress || project.status === STATUSES.review) &&
                                    <button className="btn btn-primary btn-block" onClick={() => {
                                        dispute(project.id);
                                    }}>Dispute</button>}
                            </>}

                            {messages.length === 1 &&
                                <Alert className="applied-alert mt-3" severity="info">Для можливості написання
                                    повідомлень, очікуйте на відповідь замовника.</Alert>}

                            {project.messages === 1 &&
                                <Alert className="applied-alert" severity="success">Applied, waiting for
                                    response</Alert>}
                            {project.status === STATUSES.closed &&
                                <Alert className="applied-alert mt-3" severity="info">Цей проект було закрито. Якщо у
                                    вас виникли проблеми, зверніться до служби підтримки</Alert>}
                        </div>
                    </>}
                </div>
            </div>

            <ModalReport reportProject={reportProject} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
        </div>
    );
}

ProjectComponent.propTypes = {
    project: PropTypes.object,
    notifications: PropTypes.object,
};

export default ProjectComponent;
