import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class DisputeService {
  disputesList(page) {
    return axios.get(API_URL + 'disputes?page=' + page, { headers: authHeader() });
  }

  getProjectMessages(projectId) {
    return axios.get(API_URL + 'disputes/chat/rooms/project-chat/' + projectId, { headers: authHeader() });
  }

  getRoomMessages(roomId) {
    return axios.get(API_URL + 'chat/rooms/messages/' + roomId, { headers: authHeader() });
  }

  getProjectRooms(projectId) {
    return axios.get(API_URL + 'chat/rooms/list/project/' + projectId, { headers: authHeader() });
  }

  message(projectId, message, roomId = null) {
    return axios.post(API_URL + "chat/send", {
      'project_id': projectId,
      ...(roomId && {'room_id': roomId}),
      'text': message,
    }, { headers: authHeader() });
  }
}

export default new DisputeService();
