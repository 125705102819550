import React, {useState, useEffect} from 'react';
import ProjectService from "../services/project.service";
import STATUSES from "../constants/projectStatuses";
import {Alert} from "@mui/material";
import AuthService from "../services/auth.service";
import PropTypes from "prop-types";
import DateCountdown from 'react-date-countdown-timer';
import Chat from "./messages/chat.component";
import {HiPlus, HiMinus, AiOutlineFieldTime, AiOutlineDollarCircle, IoMdClose, TbBrandTelegram} from "react-icons/all";
import ModalReport from "./modals/modalReport.component";
import ModalConfirm from "./modals/modalConfirm.component";

const ProgressProjectComponent = ({project, notifications}) => {
    const [messages, setMessages] = useState([]);
    const [chatOpened, setChatOpened] = useState(false);
    const [user, setUser] = useState({});
    const [chatRef, setChatRef] = useState(null);
    const [endTime, setEndTime] = useState(false);
    const [changeRatingSuccess, setChangeRatingSuccess] = useState(false);
    const [showRating, setShowRating] = useState(true);
    const [reportProject, setRreportProject] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [pointMessage, setPointMessage] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState('');

    const handleClickOpen = (type) => {
        setOpen(true);
        setModalType(type)
    };

    useEffect(() => {
        if (notifications.progress.rooms.length && project.rooms.length) {
            notifications.progress.rooms.forEach(id => {
                project.rooms.forEach(room => {
                    if (room.id == id) {
                        setPointMessage(true);
                    }
                })
            });
        }

    }, [notifications]);

    useEffect(() => {
        setUser(AuthService.getCurrentUser());
    }, []);

    const scrollToBottom = () => {
        if (chatRef)
            chatRef.scrollTo(0, 1000000000);
    }

    const fetchMessages = () => {
        ProjectService.getProjectMessages(project.id).then((res) => {
            setMessages(res.data.messages);
        }).then(() => {
            scrollToBottom();
        });

        scrollToBottom();
    }

    const toReview = (projectId) => {
        ProjectService.toReview(projectId).then((res) => {

        });
    }

    const changeRating = (projectId, rating) => {
        ProjectService.changeRating(projectId, rating).then((res) => {
            setShowRating(false);
            setChangeRatingSuccess(true);
        });
    }

    const dispute = (projectId) => {
        ProjectService.dispute(projectId).then((res) => {

        });
    }

    let itemColorClass;

    switch (project.status) {
        case STATUSES.progress:
        case STATUSES.review:
            itemColorClass = 'in-progress';
            break;
        case STATUSES.dispute:
            itemColorClass = 'disputed';
            break;
        default:
            itemColorClass = 'default';
    }

    return (
        <div className="mb-4 project">
            <div className={`card block ${itemColorClass}`}>
                <div className="row mb-3 pe-5 align-items-center">
                    <div className="col-12 col-lg-12 col-xl-7 mb-3 mb-xl-0">
                        <div className="title">
                            {project.name}
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-5">
                        <div className="row info">
                            <div className="col-12 col-lg-8 mb-3 mb-lg-0">
                                <div className={"item " + (endTime ? 'end-time' : '')}>
                                    <AiOutlineFieldTime/>
                                    <DateCountdown dateTo={project.timer} numberOfFigures={3}
                                                   mostSignificantFigure='day'
                                                   locales={['років', 'місяців', 'днів', 'год.', 'хивилин', 'секунд']}
                                                   locales_plural={['років', 'місяців', 'днів', 'год.', 'хивилин', 'секунд']}
                                                   callback={() => setEndTime(true)}></DateCountdown>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="item ml-2">
                                    <AiOutlineDollarCircle/> {project.price || '-'} грн.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body me-5">
                    {project.details.description}
                </div>
                {project.status === STATUSES.closed && project.rating === null && showRating &&
                    <div className="rating mt-4">
                        <p><b>Будь ласка оцініть замовника</b></p>
                        <ul>
                            <li
                                onClick={() => changeRating(project.id, false)}
                            >
                                <HiMinus className='bad'/>
                            </li>
                            <li
                                onClick={() => changeRating(project.id, true)}
                            >
                                <HiPlus className='good'/>
                            </li>
                        </ul>
                    </div>}

                {changeRatingSuccess &&
                    <Alert className="applied-alert mt-3" severity="success">Дякуємо! Ваш голос враховано</Alert>}
                <div className="card-footer-pc">
                    <ul>
                        <li>
                            <a className="button"
                               onClick={(e) => {
                                   setIsOpen(true);
                                   setRreportProject(project);
                               }}
                            ><IoMdClose/></a>
                        </li>
                        {messages.length > 0 &&
                            <li>
                                        <span className={`button chat open-chat ${pointMessage ? 'animation' : ''}`}
                                              onClick={() => {
                                                  setPointMessage(false);
                                                  setChatOpened(!chatOpened);
                                              }}>
                                            <TbBrandTelegram/>
                                        </span>
                            </li>
                        }
                        {messages.length === 0 &&
                            <li>
                                <a className={`button check open-chat ${pointMessage ? 'animation' : ''}`}
                                   onClick={() => {
                                       if (!chatOpened) {
                                           fetchMessages();
                                       }

                                       setPointMessage(false);
                                       setChatOpened(!chatOpened);
                                   }}
                                ><TbBrandTelegram/></a>
                            </li>
                        }
                    </ul>
                </div>

                {chatOpened && <>
                    <Chat
                        messages={messages}
                        project={project}
                        user={user}
                        fetchMessages={fetchMessages}
                        setChatRef={setChatRef}
                        scrollToBottom={scrollToBottom}
                        notifications={notifications}
                    />
                    <div className="actions-area mt-3">
                        {project.status !== STATUSES.closed && <>
                            {project.status === STATUSES.progress &&
                                <button className="btn me-3" onClick={() => handleClickOpen('review')}>Огляд</button>}

                            {(project.status === STATUSES.progress || project.status === STATUSES.review) &&
                                <button className="btn" onClick={() => handleClickOpen('dispute')}>Cуперечка</button>}
                        </>}

                        {project.messages === 1 &&
                            <Alert className="applied-alert" severity="success">Applied, waiting for
                                response</Alert>}
                        {project.status === STATUSES.closed &&
                            <Alert className="applied-alert" severity="info">Цей проект було закрито. Якщо у
                                вас виникли проблеми, зверніться до служби підтримки</Alert>}
                    </div>
                </>}
            </div>

            <ModalReport reportProject={reportProject} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
            <ModalConfirm roomProject={project.id} dispute={dispute} open={open} setOpen={setOpen}
                          modalType={modalType} toReview={toReview}/>
        </div>
    );
}

ProgressProjectComponent.propTypes = {
    project: PropTypes.object,
    notifications: PropTypes.object,
};

export default ProgressProjectComponent;
