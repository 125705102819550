import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import ProjectService from "../services/project.service";
import RoomComponent from "./room.component";
import 'react-tabs/style/react-tabs.css';
import PropTypes from "prop-types";
import {
    RiRadioButtonLine,
    AiOutlineStar,
    AiOutlineComment,
    AiOutlineDollarCircle,
    AiOutlineFieldTime,
    TbBrandTelegram,
    RiUser3Line, HiOutlineLockClosed, TbMessageCircleOff, HiMinus, HiPlus
} from "react-icons/all";
import STATUSES from "../constants/projectStatuses";
import ModalCommentsComponent from "./modals/modalComments.component";
import {Alert, Tooltip} from "@mui/material";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from "yup";

const TaskComponent = ({task, fetchTasks, currentUser, setCurrentUser, notifications}) => {
    const [rooms, setRooms] = useState([]);
    const [chatOpened, setChatOpened] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [showRating, setShowRating] = useState(true);
    const [rating, setRating] = useState(false);
    const [changeRatingSuccess, setChangeRatingSuccess] = useState(false);
    const [pointMessage, setPointMessage] = useState(false);

    useEffect(() => {
        if (notifications.my.rooms.length && task.rooms.length) {
            notifications.my.rooms.forEach(id => {
                task.rooms.forEach(item => {
                    if (item.id == id) {
                        setPointMessage(true);
                    }
                })
            });
        }

    }, [notifications]);

    const validationSchema = Yup.object().shape({
        comment: Yup.string()
            .required('Це поле не може бути порожнім'),
    });

    const handleCreate = (data, ownerId, executorId, projectId) => {
        ProjectService.changeExecutorRating(ownerId, executorId, projectId, rating, data.comment).then((res) => {
            setShowRating(false);
            setChangeRatingSuccess(true);

            setRating(false);
            fetchRooms();
        });
    }

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = () => {
        ProjectService.getProjectRooms(task.id).then((res) => {
            setRooms(res.data);
        });
    }

    let itemColorClass;

    switch (task.status) {
        case STATUSES.progress:
        case STATUSES.review:
            itemColorClass = 'in-progress';
            break;
        case STATUSES.dispute:
            itemColorClass = 'disputed';
            break;
        case STATUSES.closed:
            itemColorClass = 'closed';
            break;
        default:
            itemColorClass = 'default';
    }

    return (
        <div>
            <div className="mb-4 project">
                <div className={`card block ${itemColorClass}`}>
                    <div className="row mb-3 pe-5 align-items-center">
                        <div className="col-12 col-lg-7 col-xl-8 mb-3 mb-lg-0">
                            <div className="title">
                                {task.name}
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-4">
                            <div className="row info">
                                <div className="col-6">
                                    <div className="item">
                                        <AiOutlineFieldTime/>{task.duration || '-'} год.
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item">
                                        <AiOutlineDollarCircle/> {task.price || '-'} грн.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pe-lg-5 text-justify">
                        {task.details.description}
                    </div>

                    <div className="card-footer-pc">
                        <ul>
                            <li>
                                <span className={`button chat open-chat ${pointMessage ? 'animation' : ''}`}
                                      onClick={() => {
                                          setPointMessage(false);
                                          setChatOpened(!chatOpened)
                                      }}>
                                    {itemColorClass === 'closed' ? <HiOutlineLockClosed/> :
                                        rooms.length === 0 ? <TbMessageCircleOff/> : <TbBrandTelegram/>}
                                </span>
                            </li>
                        </ul>
                    </div>

                    {rooms.length > 0 && chatOpened && <Tabs>
                        <TabList className='tab-user row mt-4'>
                            {rooms.map((room, i) => <Tab key={i}
                                                         className="col-12 col-md-6 col-lg-4 position-relative mb-4 mb-lg-0">

                                <div className={`user ${room.creator.is_online ? 'online' : ''}`}>
                                    <ul className='block'>
                                        <li>
                                            <div className="position-relative">
                                                {!room.creator.profile?.photo &&
                                                    <RiUser3Line className="rating-icon"/>}
                                                {room.creator?.profile?.photo &&
                                                    <img src={room?.creator?.profile?.photo} alt=""
                                                         className="rating-image"/>}
                                                <span>{room.creator.name}</span>

                                                <Tooltip title={room.creator.is_online ? 'В мережі' : 'Не в мережі'} placement="bottom" arrow>
                                                    <span className={`position-absolute top-0 start-100 translate-middle p-2 bg-${room.creator.is_online ? 'success' : 'secondary'} border border-light rounded-circle`} />
                                                </Tooltip>
                                            </div>

                                            <Tooltip title="Рейтинг виконавця. Клікніть, щоб переглянути відгуки" placement="bottom" arrow onClick={(e) => {
                                                setSelectedRoom(room);
                                                setIsOpen(true);
                                            }}>
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                                    <span>{room?.rating}%</span>
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </div>
                            </Tab>)}
                        </TabList>
                        {rooms.map((room, i) => <TabPanel key={i}>
                            <RoomComponent
                                room={room}
                                fetchRooms={fetchRooms}
                                project={task}
                                fetchTasks={fetchTasks}
                                index={i}
                                setCurrentUser={setCurrentUser}
                                currentUser={currentUser}
                            />
                        </TabPanel>)}
                    </Tabs>
                    }

                    {task.status === STATUSES.closed &&
                        <Alert className="applied-alert mt-3" severity="info">Цей проект було закрито. Якщо у вас
                            виникли
                            проблеми, зверніться до служби підтримки</Alert>}

                    {task.status === STATUSES.closed && !task.review && showRating &&
                        <div className="rating mt-4">
                            <p><b>Будь ласка оцініть виконавця</b></p>
                            <Formik
                                initialValues={{
                                    comment: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(data) => {
                                    handleCreate(data, Number(task.owner.id), Number(task.executor_id), task.id,);
                                }}
                            >
                                {({handleSubmit}) => (<div>
                                    <Form>
                                        <ul className='mb-3'>
                                            <li
                                                onClick={() => setRating(false)}
                                                className={!rating ? 'active' : ''}
                                            >
                                                <HiMinus className='bad'/>
                                            </li>
                                            <li
                                                onClick={() => setRating(true)}
                                                className={rating ? 'active' : ''}
                                            >
                                                <HiPlus className='good'/>
                                            </li>
                                        </ul>

                                        <div className="form-group">
                                            <label htmlFor="comment" className='mb-2'><b>Коментар</b></label>
                                            <Field as="textarea" name='comment' className="form-control"/>
                                        </div>

                                        <ErrorMessage name='comment'>
                                            {msg => <div className='error'>{msg}</div>}
                                        </ErrorMessage>

                                        <div className="form-group mt-3">
                                            <button type="submit" className="bg-green-first btn">
                                                <span>Відправити</span>
                                            </button>
                                        </div>
                                    </Form>
                                </div>)}
                            </Formik>
                        </div>}

                    {changeRatingSuccess &&
                        <Alert className="applied-alert mt-4" severity="success">Дякуємо! Ваш голос враховано</Alert>}
                </div>
            </div>
            <ModalCommentsComponent data={selectedRoom} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
        </div>
    );
}

TaskComponent.propTypes = {
    task: PropTypes.object,
    fetchTasks: PropTypes.func,
    notifications: PropTypes.object,
};


export default TaskComponent;
