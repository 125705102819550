import React, {useState, useEffect} from 'react';
import DisputeService from "../services/dispute.service";
import AuthService from "../services/auth.service";
import PropTypes from "prop-types";
import ChatDispute from "./messages/chatDispute.component";
import {AiOutlineDollarCircle, AiOutlineFieldTime, TbBrandTelegram} from "react-icons/all";

const DisputeComponent = ({dispute}) => {
    const [messages, setMessages] = useState([]);
    const [chatOpened, setChatOpened] = useState(false);
    const [user, setUser] = useState({});
    const [chatRef, setChatRef] = useState(null);

    useEffect(() => {
        setUser(AuthService.getCurrentUser());
        fetchMessages();
    }, []);

    const scrollToBottom = () => {
        if (chatRef)
            chatRef.scrollTo(0, 1000000000);
    }

    const fetchMessages = () => {
        DisputeService.getProjectMessages(dispute.id).then((res) => {
            setMessages(res.data.messages);
        }).then(() => {
            scrollToBottom();
        });

        scrollToBottom();
    }

    return (
        <div>
            <div className="mb-4 project">
                <div className="card block">
                    <div className="row  mb-3 pe-5 align-items-center">
                        <div className="col-12 col-lg-7 col-xl-8 mb-3 mb-lg-0">
                            <div className="title">
                                {dispute.name}
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-4">
                            <div className="row info">
                                <div className="col-6">
                                    <div className="item">
                                        <AiOutlineFieldTime/>{dispute.duration || '-'} год.
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item ml-2">
                                        <AiOutlineDollarCircle/> {dispute.price || '-'} грн.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pe-lg-5 text-justify">
                        {dispute.details.description}
                    </div>

                    <div className="card-footer-pc">
                        <ul>
                            <li>
                                <span className="button chat open-chat"
                                      onClick={() => setChatOpened(!chatOpened)}>
                                    <TbBrandTelegram/>
                                </span>
                            </li>
                        </ul>
                    </div>

                    {chatOpened && <>
                        <ChatDispute
                            messages={messages}
                            dispute={dispute}
                            fetchMessages={fetchMessages}
                            setChatRef={setChatRef}
                            scrollToBottom={scrollToBottom}
                        />
                    </>}
                </div>
            </div>
        </div>
    );
}

DisputeComponent.propTypes = {
    dispute: PropTypes.object,
};

export default DisputeComponent;