import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import ProjectService from "../../services/project.service";
import * as Yup from "yup";
import {FaUpload} from 'react-icons/fa';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import FormData from 'form-data'
import AdminMessage from "./adminMessage.component";
import AuthMessage from "./authMessage.component";
import Message from "./message.component";
import {VscAccount, FiMapPin, FaTelegramPlane} from "react-icons/all";
import STATUSES from "../../constants/projectStatuses";
import {Tooltip} from "@mui/material";

const Chat = ({messages, project, user, room, fetchMessages, setChatRef, scrollToBottom, disabledSecondMessage}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingStatus, setUploadingStatus] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleMessage = (e, project) => {
        let data = new FormData();
        let attachments = e.attachments;

        if (attachments && attachments.length > 0) {
            Object.keys(e.attachments).map((el) => {
                data.append('attachments[]', e.attachments[el])
            });
        }

        data.append('text', e.message);
        data.append('receiver_id', project.owner.id !== user.id ? project.owner.id : room.creator_id);

        if (room) {
            data.append('project_id', room.project_id);
            data.append('room_id', room.id);
        } else {
            data.append('project_id', project.id);
        }

        ProjectService.message(data, setIsUploading, setUploadingStatus).then(() => {
                setIsUploading(false);
                setUploadingStatus(null);
                setSelectedFiles([]);

                fetchMessages();
            }, (error) => {
                setIsUploading(false);
                setUploadingStatus(null);
                setSelectedFiles([]);

                console.log('err sent');
            }
        );

        scrollToBottom();
    }

    const validationSchema = Yup.object().shape({
        message: Yup.string()
            .required('Це поле не може бути порожнім'),
    });

    return (
        <div className="card-messenger mt-4">
            <div className="row gx-0">
                <div className={`col-12 ${project.status !== STATUSES.closed ? 'col-lg-8' : ''}`}>
                    <div className="chat msg-lg-body messages scrollbar-messenger"
                         ref={ref => {
                             scrollToBottom();
                             setChatRef(ref);
                         }}>
                        {messages.length > 0 && <ul>
                            {messages.map((message, i) => <div className="message" key={i}>
                                {(() => {
                                    if (message.is_admin) {
                                        return <AdminMessage message={message} index={i}/>
                                    } else {
                                        if (message.user.id == user.id) {
                                            return <AuthMessage message={message} index={i}/>
                                        } else {
                                            return <Message message={message} index={i}/>
                                        }
                                    }
                                })()}
                            </div>)}
                        </ul>}
                    </div>
                </div>

                {project.status !== STATUSES.closed &&
                    <div className="col-12 col-lg-4">
                        <div className="msg-lg-info">
                            <ul className='mb-3'>
                                <li className="author position-relative">
                                    <Tooltip title={project?.owner_data?.is_online ? 'В мережі' : 'Не в мережі'}
                                             placement="top"
                                             arrow>
                                        <div>
                                            <VscAccount/><b>Замовник:</b> {project.owner.name}

                                            {project?.owner_data &&
                                                <span
                                                    className={`text-right point p-2 bg-${project?.owner_data?.is_online ? 'green-first' : 'white'}`}/>
                                            }
                                        </div>
                                    </Tooltip>
                                </li>
                            </ul>

                            <div className="msg-lg-input">
                                {project.messages !== 1 &&
                                    <Formik
                                        initialValues={{
                                            message: '',
                                            attachments: null,
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(e, {resetForm}) => {
                                            handleMessage(e, project);
                                            resetForm({values: ''});
                                        }}
                                    >
                                        {({values, setFieldValue}) => (
                                            <Form>
                                                <Field type='text'
                                                       name='message'
                                                       className="message"
                                                       placeholder="Напишіть повідомлення"
                                                       disabled={disabledSecondMessage && messages.length === 1}
                                                />
                                                <button
                                                    className="go"
                                                    disabled={disabledSecondMessage && messages.length === 1}
                                                >
                                                    <FaTelegramPlane/>
                                                </button>
                                                <ErrorMessage name='message'>
                                                    {
                                                        msg => <div className='error'>{msg}</div>
                                                    }
                                                </ErrorMessage>

                                                <input name="attachments"
                                                       type="file"
                                                       accept=".png,.jpeg,.jpg,.pdf,.mov,.mp4,.avi,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,.7z"
                                                       onChange={(event) => {
                                                           let err = false;

                                                           Array.from(event.currentTarget.files).forEach(file => {
                                                               if (file.size > 50e6) {
                                                                   err = true;
                                                               }
                                                           });

                                                           if (!err) {
                                                               setFieldValue("attachments", event.currentTarget.files);

                                                               if (event.currentTarget.files) {
                                                                   setSelectedFiles(event.currentTarget.files);
                                                               }
                                                           } else {
                                                               alert('Розмір кожного файлу повинен бути не більше 50mb.');
                                                           }
                                                       }}
                                                       className="form-control upload-file-hidden"
                                                       multiple
                                                       id={'upload-file-' + project.id}
                                                />
                                                <label className="upload-file-label"
                                                       htmlFor={'upload-file-' + project.id}>
                                                    <FaUpload/>
                                                    <span>
                                                        {selectedFiles && selectedFiles.length > 0 ? `Вибрано файлів: ${selectedFiles.length}` : 'Завантажити файли'}
                                                    </span>
                                                </label>

                                                {isUploading &&
                                                    <div className="progress mt-3">
                                                        <div
                                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar" aria-valuenow={`${uploadingStatus}`}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100" style={{width: `${uploadingStatus}%`}}>
                                                            {uploadingStatus}%
                                                        </div>
                                                    </div>
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

Chat.propTypes = {
    project: PropTypes.object,
    user: PropTypes.object,
    room: PropTypes.object,
    fetchMessages: PropTypes.func,
    setChatRef: PropTypes.func,
    scrollToBottom: PropTypes.func,
};

export default Chat;
