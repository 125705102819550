import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class CategoryService {
  categoriesList(page) {
    return axios.get(API_URL + 'categories', { headers: authHeader() });
  }
}

export default new CategoryService();
