import React, {useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import AuthService from "../services/auth.service";
import ProjectService from "../services/project.service";
import ProgressProjectComponent from "../components/progressProject.component";

const ProgressPage = ({notifications}) => {
    const [redirect, setRedirect] = useState(null);
    const [projectsReady, setProjectsReady] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [progressProjects, setProgressProjects] = useState([]);

    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();

        if (!currentUser) setRedirect("/");

        fetchProgressProjects();
    }, [activePage]);

    const fetchProgressProjects = () => {
        setProjectsReady(false);

        ProjectService.projectsProgressList(activePage).then((res) => {
            setProgressProjects(res.data.items);
            setProjectsReady(true);
            setPagesCount(res.data.last_page || 1);
        });
    }

    const handlePageChange = (event, page) => {
        setActivePage(page);
    }

    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
        <div>

            <div className='row'>
                <div className="col-12 col-lg-9 m-auto mb-5">
                    <h1 className="title">
                        Завдання в роботі
                    </h1>

                    {(projectsReady) ?
                        <div>
                            {progressProjects && progressProjects.length === 0 && <div>У вас немає завдань</div>}

                            {progressProjects && progressProjects.map((project, index) =>
                                <ProgressProjectComponent
                                    key={index}
                                    project={project}
                                    notifications={notifications}
                                />)}

                            {pagesCount > 1 && <Pagination
                                page={activePage}
                                count={pagesCount}
                                onChange={handlePageChange}
                                color="secondary"
                                className="mt-3"
                            />}

                        </div> : null}
                </div>
            </div>
        </div>
    );
}

export default ProgressPage;
