import React, {useState, useEffect} from 'react';
import {Switch, Route} from "react-router-dom";
import "./App.scss";
import 'jquery';
import 'bootstrap';
import Menu from "./components/menu.component";
import LoginPage from "./pages/login.page";
import RegisterPage from "./pages/register.page";
import ProfilePage from "./pages/profile.page";
import BalancePage from "./pages/balance.page";
import ProjectsPage from "./pages/projects.page";
import DisputesPage from "./pages/disputes.page";
import ProgressPage from "./pages/progress.page";
import TasksPage from "./pages/tasks.page";
import CreateProjectPage from "./pages/createProject.page";
import UserService from "./services/user.service";

const App = () => {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [notifications, setNotifications] = useState({
        open: {
            exists: false,
            rooms: false,
        },
        progress: {
            exists: false,
            rooms: false,
        },
        my: {
            exists: false,
            rooms: false,
        },
    });

    const heartbeat = () => {
        UserService.heartbeat().then((res) => {
            setNotifications(res.data.notifications);
        });
    }

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem('user')));
        UserService.refreshProfileData(currentUser, setCurrentUser);
        heartbeat();

        setInterval(function () {
            heartbeat();
        }, 30000);
    }, []);

    return (
        <div>
            <div className="content">
                <Menu notifications={notifications} currentUser={currentUser}/>

                <div className="container">
                    <Switch>
                        <Route exact path="/" render={() => (
                            <LoginPage setCurrentUser={setCurrentUser}/>)}
                        />
                        <Route exact path="/login" render={() => (
                            <LoginPage setCurrentUser={setCurrentUser}/>)}
                        />
                        <Route exact path="/register" component={RegisterPage}/>
                        <Route exact path="/profile" render={() => (
                            <ProfilePage setCurrentUser={setCurrentUser} currentUser={currentUser}/>)}
                        />
                        <Route exact path="/balance" component={BalancePage}/>
                        <Route exact path="/projects" render={() => (
                            <ProjectsPage notifications={notifications} />)}
                        />
                        <Route exact path="/disputes" component={DisputesPage}/>
                        <Route exact path="/progress" render={() => (
                            <ProgressPage notifications={notifications} />)}
                        />
                        <Route exact path="/tasks" render={() => (
                            <TasksPage notifications={notifications} setCurrentUser={setCurrentUser} currentUser={currentUser}/>)}
                        />
                        <Route exact path="/create" component={CreateProjectPage}/>
                    </Switch>

                </div>
            </div>
            <footer>
                <div className="container">
                    <span>Вжик.Com - Сервіс швидких завдань © 2022<br /><br />
                        Alpha version - поки можливі збої в роботі сервісу.<br />
                        Проте, обіцяємо виправляти все миттю як тільки дізнаємось :)
                    </span>
                </div>
            </footer>
        </div>
    );
}

export default App;
